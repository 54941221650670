



















































































































































.text-name {
  display: inline-block;
  line-height: 0.9;

  @media (min-width: 420px) {
    width: 115px!important;
  } 
}

.cadastro-usuario >>> .v-data-table__wrapper table {
  min-width: auto;
}

.box-table::v-deep {

  table {
    min-width: 400px;
  }

  .cancelado {
    color: #F00;
  }

  .mobile {
    @media (max-width: 420px) {
      display: none;
    } 
  }

  .v-btn {
    height: 30px !important;

  }
}

